import { customSelect, custonInput } from "./customComponents"

export let dataAPI = []

export async function getCategories() {
    const category = document.querySelector('#category-choose')

    if (!category) return

    let dataCategories = await fetch('/api/calculete-category/', {
        method: 'GET'
    })
    if (dataCategories.ok) {
        dataCategories = await dataCategories.json()
        renderOptionsInSelect(category, dataCategories.results)
    } else {
        console.error(dataCategories);
    }
}

function renderOptionsInSelect(container, data) {
    const optionContainer = container.querySelector('.custom-select__body')
    optionContainer.innerHTML = ``
    let template = ``
    data.forEach(el => {
        template = template + 
        `<div data-id="${el.id}" class="option">${el.name}</div>`
    })
    optionContainer.insertAdjacentHTML('afterbegin', template) 

    customSelect()
}

export async function getProducts(id) {

    const product = document.querySelector('#product-choose')

    console.log(id)

    const url = `/api/calculete-category/${id}/details/`
    let dataProducts = await fetch(url, {
        method: 'GET'
    })
    if (dataProducts.ok) {
        dataProducts = await dataProducts.json()
        console.log(dataProducts)
        renderOptionsInSelect(product, dataProducts.products)
        
        dataAPI = dataProducts.products

        // getFieldsProduct(id, dataProducts.products)
    }
}

export async function getFieldsProduct(id) {
    
    const containerSelect = document.querySelector('.calculate-block__inputs__custom')
    containerSelect.innerHTML = ``

    const activeProduct = dataAPI.find(prod => prod.id == id)

    const container = document.querySelector('.calculate-block__inputs__custom')

    const item = `
        <label for="input" class="custom-input">
            <span class="placeholder">Площадь нанесения, м²</span>
            <input id="input" type="number">
        </label>
    `

    container.insertAdjacentHTML('beforeend', item)

    custonInput()

    for (let coff of activeProduct.coefficients) {
        console.log(coff)
        createSelect(coff.name, coff.coefficients)
    }
}

function createSelect(placeholder, options = []) {
    
    const container = document.querySelector('.calculate-block__inputs__custom')
    let templateOption = ``
    for (let option of options) {
        console.log(option)
        templateOption = templateOption + 
            `<div data-id="${option.id}" class="option">${option.name}</div>`
    }
    console.log(placeholder);
    console.log(templateOption)

    let item = `
        <div class="custom-select">
            <div class="custom-select__header">
                <span class="placeholder">${placeholder}</span>
                <span class="value"></span>
                
                <svg class="custom-select__chevron" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g id="outlined / chevron-down">
                    <path id="Icon" d="M6 9L12 15L18 9" stroke="#1C1917" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </g>
                </svg>
            </div>
            <div class="custom-select__body">
                ${templateOption}
            </div>
        </div>
    `

    container.insertAdjacentHTML('beforeend', item)   
    customSelect()
    console.log('work')
}

export function calculate() {

    const mainContainer = document.querySelector('.calculate-block')
    const button = mainContainer.querySelector('.calculate')
    
    const deleteButton = mainContainer.querySelector('.delete')

    if (!mainContainer) return
    
    button.onclick = () => {
        checkSelect(mainContainer)
    }

    deleteButton.onclick = () => {
        const category = document.querySelector('#category-choose')
        const product = document.querySelector('#product-choose')

        category.querySelector('.value').textContent = ``
        product.querySelector('.value').textContent = ``
        
        category.querySelector('.placeholder').classList.remove('top-placeholder')
        product.querySelector('.placeholder').classList.remove('top-placeholder')

        const containerSelect = document.querySelector('.calculate-block__inputs__custom')
        containerSelect.innerHTML = ``
    }
}

function checkSelect(mainContainer) {
    let error = false
    let dataSelect = []
    
    const selects = mainContainer.querySelectorAll('.custom-select')
    const inputContainer = document.querySelector('.custom-input')
    console.log(inputContainer);
    const input = inputContainer.querySelector('input')

    selects.forEach(el => {
        const value = el.querySelector('.value')
        dataSelect.push(value.textContent)
        console.log(el);
        if (value.textContent == '') {
            el.classList.add('error-select')
            error = true
        } else if (el.classList.contains('error-select')) {
            el.classList.remove('error-select')
        }
    })

    if (input.value == '') {
        console.log('complete')
        inputContainer.classList.add('error-input')
        error = true
    } else {
        inputContainer.classList.remove('error-input')
    }

    if (!error) {
        sendInfo(input.value, dataSelect)
    }

}

async function sendInfo(metrs, data=[]) {
    let id = sessionStorage.getItem('product');
    let url = `/api/calculete-products/${id}/calculate/?area=${metrs}&coefficients=`
    let newData = data.slice(2)
    
    for (let data of newData) {
        url = url + data + `,`
    }
    
    url = url.replace(/,$/, "")
    
    let dataApi = await fetch(url, { method: 'GET' })
    dataApi = await dataApi.json()

    let dataProduct = await fetch(`/api/calculete-products/${id}/`)
    dataProduct = await dataProduct.json()

    blockInfo(dataProduct, dataApi)
}

function blockInfo(dataProduct, dataInfo) {
    const screen = document.querySelector('.product-block__empty-text')
    screen.classList.add('none')

    const photoBlock = document.querySelector('.product-block__product-photo')
    photoBlock.classList.remove('none')

    const textBlock = document.querySelector('.product-block__product-info')
    textBlock.classList.remove('none')


    const blockContainer = document.querySelector('.product-block')
    const textContainer = blockContainer.querySelector('.product-block__product-info')
    
    const titleProdcut = textContainer.querySelector('#productName')
    const needKG = textContainer.querySelector('#kg_need')
    const countProduct = textContainer.querySelector('#countProduct')

    const imgProduct = blockContainer.querySelector('img')

    const buttonTrans = textContainer.querySelector('.button-trans')
    const buttonWhereBuy = textContainer.querySelector('.button-where-buy')

    titleProdcut.textContent = dataProduct.name
    needKG.textContent = dataInfo.required_kg + ' кг'
    countProduct.textContent = dataInfo.required_packages
    
    buttonTrans.href = `https://brigadir-rf.ru/product/${dataProduct.slug}`
    buttonWhereBuy.href = `https://brigadir-rf.ru/product/${dataProduct.slug}`

    const url = `${window.location.protocol}//${window.location.host}${dataProduct.main_photo}`
    
    imgProduct.src = url    
}