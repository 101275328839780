import { setDisplayedChild } from "./turkneyCard.js";

export function sliders() {
    const orderReceipt = new Swiper('.order-receipt__slider', {
        slidesPerView: 3.583,
        spaceBetween: getRem() * 1,
        breakpoints: {
            769: {
                slidesPerView: 9.73,
                spaceBetween: getRem() * 1,
                slidesOffsetBefore: 0,
                slidesOffsetAfter: 0,
            },
            620: {
                slidesPerView: 7,
            },
            520: {
                slidesPerView: 4.89,
            },
            420: {
                slidesPerView: 3.87,
            },
        },
    });

    const searcher = new Swiper('.searcher-slider', {
        slidesPerView: 3.03,
        spaceBetween: getRem() * 1,
        slidesOffsetBefore: getRem() * 1,
        slidesOffsetAfter: getRem() * 1,
        breakpoints: {
            769: {
                slidesOffsetBefore: getRem() * 1.5,
                slidesOffsetAfter: getRem() * 1.5,
            },
        },
        scrollbar: {
            el: '.searcher-slider-scrollbar',
            draggable: true,
        },
        mousewheel: {
            forceToAxis: true
        },
    });

    const mainHero = new Swiper('.main-hero-slider', {
        slidesPerView: 1,
        autoplay: {
            delay: 5000,
        },
        pagination: {
            el: '.main-slider-pagination',
            clickable: true
        },
        mousewheel: {
            forceToAxis: true
        }
    });

    const mainProducts = new Swiper('.main-products-slider', {
        slidesPerView: 2.25,
        spaceBetween: getRem() * 1,
        breakpoints: {
            769: {
                slidesPerView: 4,
                spaceBetween: getRem() * 1.25,
            },
        },
        autoplay: {
            delay: 3000,
        },
        // pagination: {
        //     el: '.main-slider-pagination',
        //     clickable: true
        // },
        mousewheel: {
            forceToAxis: true
        }
    });


    const personalAccount = new Swiper('.personal-account-slider', {
        slidesPerView: 9.7,
        spaceBetween: getRem() * 1,
        mousewheel: {
            forceToAxis: true
        },
    });

    const brandsMain = new Swiper('.brands-slider', {
        slidesPerView: "auto",
        spaceBetween: getRem() * 4,
        slidesOffsetBefore: getRem() * 1,
        slidesOffsetAfter: getRem() * 1,
        769: {
            slidesPerView: "auto",
            slidesOffsetBefore: getRem() * 0,
            slidesOffsetAfter: getRem() * 0,
        },
        mousewheel: {
            forceToAxis: true
        },
    });

    const turnkeySlider = new Swiper('.turnkey-slider', {
        slidesPerView: 1.1,
        spaceBetween: getRem() * 1,
        slidesOffsetBefore: getRem() * 1,
        slidesOffsetAfter: getRem() * 1,
        breakpoints: {
            769: {
                slidesPerView: 2,
                spaceBetween: getRem() * 1.25,
                slidesOffsetBefore: getRem() * 0,
                slidesOffsetAfter: getRem() * 0,
            },
            520: {
                slidesPerView: 1.75,
                spaceBetween: getRem() * 1,
            },
            320: {
                slidesPerView: 1.24,
                spaceBetween: getRem() * 1,
            },
        }
    });

    const turkneyCardSliders = document.querySelectorAll('.turkney-card__slider');

    turkneyCardSliders.forEach((mainSlider, index) => {
        const subSlider = document.querySelectorAll('.turkney-card__sub-slider')[index];
        const mainSwiper = new Swiper(mainSlider, {
            slidesPerView: 1.17,
            spaceBetween: getRem() * 1,
            thumbs: {
                swiper: subSlider,
            },
            breakpoints: {
                769: {
                    slidesPerView: 1,
                    spaceBetween: 0,
                },
            },
            on: {
                slideChange: function () {
                    setDisplayedChild()
                },
              },
        });

        new Swiper(subSlider, {
            slidesPerView: 6,
            spaceBetween: getRem() * 1,
        });
    });


    const productCardSub = new Swiper('.product-card__sub-slider', {
        slidesPerView: 5.09,
        direction: 'horizontal',
        spaceBetween: getRem() * 1,
        breakpoints: {
            520: {
                direction: 'vertical',
            },
        },
    });

    const productCard = new Swiper('.product-card__main-slider', {
        slidesPerView: 1,
        thumbs: {
            swiper: productCardSub,
        },
    });

    const documentationFile = new Swiper('.documentation__slider', {
        slidesPerView: 1.1,
        spaceBetween: getRem() * 1,
        slidesOffsetBefore: getRem() * 1,
        slidesOffsetAfter: getRem() * 1,
        breakpoints: {
            769: {
                slidesPerView: 4,
                spaceBetween: getRem() * 1.25,
                slidesOffsetBefore: getRem() * 0,
                slidesOffsetAfter: getRem() * 0,
            },
            520: {
                slidesPerView: 1.75,
                spaceBetween: getRem() * 1,
            },
            320: {
                slidesPerView: 1.24,
                spaceBetween: getRem() * 1,
            },
        },
    });

    const basketPositions = new Swiper('.basket-order-slider', {
        slidesPerView: 3,
        spaceBetween: getRem() * 1.25,
        slidesOffsetBefore: getRem() * 1,
        slidesOffsetAfter: getRem() * 1,
        breakpoints: {
            769: {
                slidesPerView: 3.717,
                slidesOffsetBefore: getRem() * 0,
                slidesOffsetAfter: getRem() * 0,
                spaceBetween: getRem() * 1.25,
            },
            520: {
                slidesPerView: 7,
                spaceBetween: getRem() * 1,
            },
            320: {
                slidesPerView: 3.58,
                spaceBetween: getRem() * 1,
            },
        },
    });
    const basketSuccessPositions = new Swiper('.basket-success-slider', {
        slidesPerView: 3,
        spaceBetween: getRem() * 1.25,
        slidesOffsetBefore: getRem() * 1,
        slidesOffsetAfter: getRem() * 1,
        breakpoints: {
            769: {
                slidesPerView: 4.198,
                slidesOffsetBefore: getRem() * 0,
                slidesOffsetAfter: getRem() * 0,
                spaceBetween: getRem() * 1.25,
            },
            520: {
                slidesPerView: 7,
                spaceBetween: getRem() * 1,
            },
            320: {
                slidesPerView: 3.58,
                spaceBetween: getRem() * 1,
            },
        },
    });


    const technicalSub = new Swiper('.technical_slider_sub', {
        slidesPerView: 6,
        spaceBetween: getRem() * 1,
    });

    const technicalSlider = new Swiper('.technical_slider_main', {
        slidesPerView: 1,
        thumbs: {
            swiper: technicalSub,
        },
    });

    const palletSliderMobile = new Swiper('.pallet-section_mobile', {
        slidesPerView: 1.2,
        spaceBetween: getRem() * 1,
        slidesOffsetBefore: getRem() * 1,
        slidesOffsetAfter: getRem() * 1,
    });

    const palletSliderDesktop = new Swiper('.slider-pallete', {
        slidesPerView: 1,
        spaceBetween: getRem() * 3
    });


    function getRem() {
        var element = document.querySelector("html");
        var fontSize = window.getComputedStyle(element).fontSize;
        var numericFontSize = parseInt(fontSize, 10);
        return fontSize.slice(0, -2)
    }

}