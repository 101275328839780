// interactiveColor.js

import {changeHouseWallColor, changeHousePlinthColor} from "../paintingHome/script.js";
import {setTitle} from "../select.js";
import {appendPalletCard} from "./appendElements.js";

let globalPaletteId = 1;
let selectedElement = 'wall'; // По умолчанию выбрана стена

export function interactiveColor() {
    const wallButton = document.querySelectorAll('.wallButton');
    const plinthButton = document.querySelectorAll('.plinthButton');

    // Устанавливаем обработчики событий для кнопок
    wallButton.forEach(btn => {
        btn.addEventListener('click', () => {
            selectedElement = 'wall';
            plinthButton.forEach(btn => {
                btn.classList.remove('active')
            });
            wallButton.forEach(btn => {
                btn.classList.add('active')
            });
            materialsStateShown()
            getAllForQuery('Facade')
                .then(res => {
                    appendNewPallet(res)
                })
        });
    });

    plinthButton.forEach(btn => {
        btn.addEventListener('click', () => {
            selectedElement = 'plinth';
            wallButton.forEach(btn => {
                btn.classList.remove('active')
            });
            plinthButton.forEach(btn => {
                btn.classList.add('active')
            });
            materialsStateHidden();
            getAllForQuery('Foundation')
                .then(res => {
                    appendNewPallet(res)
                    console.log(res.json())
                }).catch(error => {
                console.log(error);
            })
        });
    })

    const palletSelectors = Array.from(document.querySelector('fieldset[name="color"]').querySelectorAll('input[name="pallet"]'));

    palletSelectors.forEach(input => {
        input.addEventListener('click', event => {
            const palletId = event.target.value;
            globalPaletteId = palletId;
            getColors(palletId);
            setPalletData(palletId);
        });
    });


    const selectPallet = palletSelectors.find(input => input.checked);
    if (selectPallet) {
        setPalletData(selectPallet.value);
    }
}

export async function setColors(colors) {
    const container = document.querySelector('#palletColors');
    container.innerHTML = '';

    let colorElements = '';

    for (let i = 0; i < colors.length; i++) {
        const color = colors[i];

        colorElements += `
            <label class="painting-section__pallet-block__select-color-block__color-item">
                <input type="radio" 
                       name="palletColor" 
                       value="${color.id}" 
                       ${i === 0 ? 'checked="checked"' : ''}>
                ${color.name}
            </label>
        `;

        if (i === 0) {
            const shades = await getShades(color.id);
            await setShades(shades.shades);
        }
    }

    container.innerHTML = colorElements;

    container.querySelectorAll('label').forEach(label => {
        label.addEventListener("click", async function (event) {
            const shades = await getShades(event.target.value);
            await setShades(shades.shades);
        });
    });
}


export async function setShades(shades) {
    const container = document.querySelector('#palletShades');
    container.innerHTML = '';

    container.innerHTML = shades.map((shade, i) => `
        <label 
            class="painting-section__pallet-block__select-hue-block__hue-item ${i === 0 ? 'selected' : ''}" 
            style="${shade.texture ? `background-image: url('${shade.texture}')` : `background-color: ${shade.code_hex}`}"
        >
            <input 
                id="hue${shade.id}" 
                type="radio" 
                name="palletHue" 
                value="${shade.code_hex}" 
                ${shade.texture ? 'class="texture"' : ''} 
                ${i === 0 ? 'checked' : ''}
            >
            <span class="painting-section__pallet-block__select-hue-block__hue-item__name">${shade.name}</span>
        </label>
    `).join('');

    document.querySelectorAll('.painting-section__pallet-block__select-hue-block__hue-item').forEach((label, index) => {
        label.addEventListener('click', function () {
            document.querySelector('.painting-section__pallet-block__select-hue-block__hue-item.selected')?.classList.remove('selected');
            label.classList.add('selected');

            const shade = shades[index];

            if (selectedElement === 'wall') {
                changeHouseWallColor(shade.code_hex);
            } else if (selectedElement === 'plinth') {
                changeHousePlinthColor(shade.texture);
            }
        });
    });

    if (shades.length > 0 && selectedElement) {
        setTimeout(() => {
            const firstShade = shades[0];
            if (selectedElement === 'wall') {
                changeHouseWallColor(firstShade.code_hex);
            } else if (selectedElement === 'plinth') {
                changeHousePlinthColor(firstShade.texture);
            }
        }, 100);
    }


    const notLoadedElem = document.querySelector('.painting-section__not-loaded');
    if (notLoadedElem) {
        setTimeout(() => {
            const wallButton = document.querySelector('.wallButton');
            wallButton.click()
            notLoadedElem.parentElement.removeChild(notLoadedElem)
        }, 1000);
    }
}

export async function setPalletData(palletId) {
    const palletData = await getColors(palletId);
    // const paintPriceBlock = document.querySelector('#paintPrice');
    setColors(palletData.colors);
    // paintPriceBlock.innerText = `Стоимость за литр краски: ${palletData.price} ₽`;
    sessionStorage.setItem('paintPrice', palletData.price);
    calculateTotalPrice();
}

export async function calculateTotalPrice() {
    // const square = document.querySelector('.square').value ? document.querySelector('.square').value : 0;

    // const paintTotalPriceBlock = document.querySelector('#paintTotalPrice');
    // const price = await getPalletPrice(globalPaletteId, square);
    // if(paintTotalPriceBlock) paintTotalPriceBlock.innerText = `${price.price} литров`;
}

async function getAllForQuery(query) {
    const response = await fetch(
        `/api/pallets/` + "?type=" + query,
        {
            "method": "GET"
        }
    );
    if (response.ok) return response.json();
    else throw new Error(response.statusText);
}


export async function getColors(palletId) {
    const response = await fetch(
        `/api/pallets/${palletId}/`,
        {
            "method": "GET"
        }
    );
    if (response.ok) return response.json();
    else throw new Error(response.statusText);
}

async function getShades(colorId) {
    const response = await fetch(
        `/api/colors/${colorId}/`,
        {
            "method": "GET"
        }
    );
    if (response.ok) return response.json();
    else throw new Error(response.statusText);
}


function appendNewPallet(res) {
    const selectContent = document.querySelector('.palleteColorsSelect');
    let paletteFragment = new DocumentFragment();

    res.results.forEach(palette => {
        const [input, label] = appendPalletCard(palette);
        paletteFragment.appendChild(input);
        paletteFragment.appendChild(label);
    });

    Array.from(selectContent.children).forEach(child => {
        child.parentElement.removeChild(child)
    });
    selectContent.appendChild(paletteFragment);

    const inputChecked = selectContent.querySelector('input');
    const selectSingle_title = document.querySelector('.__select__title');

    let defaultText = '';

    if (inputChecked) {
        inputChecked.setAttribute.state = 'checked';
                
        defaultText = inputChecked.getAttribute('data-name');
        inputChecked.click();
    }

    if (selectSingle_title) setTitle(selectSingle_title, defaultText);

    if (inputChecked.dataset.img) {
        const img = document.createElement('img')
        img.src = inputChecked.dataset.img
        img.classList = "color-item-img"
        
        selectSingle_title.prepend(img)
    }
}

function materialsStateHidden() {
    const materials = document.querySelectorAll('.materialsToChoose');
    materials.forEach(material => {
        material.style.opacity = '0';
        material.style.scale = '0';
        setTimeout(() => {
            material.style.display = 'none';
        }, 100);
    })
}

function materialsStateShown() {
    const materials = document.querySelectorAll('.materialsToChoose');
    materials.forEach(material => {
        material.style.display = 'flex';
        setTimeout(() => {
            material.style.opacity = '1';
            material.style.scale = '1';
        }, 100);
    })
}