import {
    getColors,
    setPalletData
} from './script.js';
export function appendPalletCard(palette) {


    const paletteName = palette.name;
    const paletteId = palette.id;
    
    const input = document.createElement('input');
    const label = document.createElement('label');
    
    input.id = `singleSelect${paletteId}`;
    input.classList.add('__select__input');
    input.type = 'radio';
    input.name = 'pallet';
    input.value = `${paletteId}`;
    input.setAttribute("data-name", `${paletteName}`)
    input.setAttribute("data-img", palette.image)

    label.setAttribute("for", `singleSelect${paletteId}`);
    label.classList.add('__select__label');
    label.id = `${paletteId}`;
    label.innerText = `${paletteName}`;
    label.setAttribute("data-img", palette.image)

    input.addEventListener('click',() => {
        getColors(paletteId);
        setPalletData(paletteId);
    })


    const selects = document.querySelectorAll('.__select');


    label.addEventListener('click', (evt) => {
        selects.forEach(select => {
            // console.log('its evt');
            // console.log(evt.target.dataset.img);
            
            select.querySelector('.__select__title').textContent = evt.target.textContent;
        
            if (evt.target.dataset.img) {
                const img = document.createElement('img')
                img.src = evt.target.dataset.img
                img.classList = "color-item-img"
                
                select.querySelector('.__select__title').prepend(img)
                // console.log(img)
            }
        });
        
        selects.forEach(select => {select.setAttribute('data-state', '')});
    });

    return [input, label];
}