import { noPaginationBlock } from "../../pagination.js";
export function addArticlesCards(data,list) {
    const fragment = document.createDocumentFragment();
    data.results.forEach((el) => {
        const newArticle = addCard(el)

        fragment.appendChild(newArticle);

        const hr = document.createElement('hr');
        fragment.appendChild(hr);
    });
    list.appendChild(fragment);
    if(!data.next) {
        noPaginationBlock()
    }
}

function addCard(el) {
    let dataImage = el['image'] ? el['image'] : '/static/assets/images/img/stub.png';
    const newArticle = document.createElement('a');
    newArticle.classList.add('blog-card', 'blog-card_size_large');
    newArticle.href = '/articles/' + el['slug'];
    newArticle.innerHTML = `
        <div class="blog-card_size_large__info-block">
            <div class="blog-card_size_large__title-block">
                <div class="blog-card_size_large__title-block__title">
                    ${el['name']}
                </div>
                <div class="blog-card_size_large__title-block__text">
                    ${el['content']}
                </div>
            </div>
            <div class="blog-card_size_large__info-block__sub-title">
                Подробнее
            </div>
        </div>
        <div class="blog-card_size_large__img">
            <img loading="lazy" src="${dataImage}">
        </div>
    `
    return newArticle;
}
