import { getFieldsProduct, getProducts } from "./getCategories.js"

export function custonInput() {
    const allCustomInput = document.querySelectorAll('.custom-input')
    allCustomInput.forEach(el => {
        const span = el.querySelector('.placeholder')
        const input = el.querySelector('input')
        // const popover = el.querySelector('.popover')

        input.oninput = () => {
            if (input.value) {
                span.classList.add('top-placeholder')
            } else {
                span.classList.remove('top-placeholder') 
            }
        }
    })
}

export function customSelect() {
    const allCustomSelect = document.querySelectorAll('.custom-select');

    allCustomSelect.forEach(el => {
        let state = false;
        
        const header = el.querySelector('.custom-select__header');
        const storage = el.getAttribute('data-storage')
        // console.log(storage);
        const body = el.querySelector('.custom-select__body');
        const placeholder = header.querySelector('.placeholder');
        const value = header.querySelector('.value');
        const svg = el.querySelector('svg');
        const items = el.querySelectorAll('.option');

        header.onclick = (event) => {
            event.stopPropagation();
            state = !state;
            body.classList.toggle('block', state);
            svg.classList.toggle('rotate', state);
        };

        items.forEach(item => {
            item.onclick = async (event) => {
                event.stopPropagation();
                value.textContent = item.textContent;
                state = false;
                body.classList.remove('block');
                svg.classList.remove('rotate');
                placeholder.classList.add('top-placeholder');
                // console.log(item);
                let id = item.getAttribute('data-id')
                // console.log(id)
                if (storage != undefined && storage == 'category') {
                    await getProducts(id)
                } else if (storage != undefined && storage == 'product') {
                    sessionStorage.setItem('product', id)
                    await getFieldsProduct(id)
                }
            };
        });

        document.onclick = (event) => {
            if (!el.contains(event.target)) {
                state = false;
                body.classList.remove('block');
                svg.classList.remove('rotate');
            }
        }
    })
}

export function colorPicker() {
    const allColorPickers = document.querySelectorAll('.color-picker')
    allColorPickers.forEach(el => {
        let open = false
        const svg = el.querySelector('.color-picker__toggle')
        const container = el.querySelector('.color-picker__container')
        svg.onclick = () => {
            open = !open
            if (open) {
                container.classList.remove('max-h-fit')
                svg.classList.remove('rotate')
            } else {
                container.classList.add('max-h-fit')
                svg.classList.add('rotate')
            }
        }
    })
}